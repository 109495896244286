import { Alert, Breadcrumb, Button, Space, Table } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { fetcher } from '../../utils/swr'

export default function Admin() {
    return (
        <div style={{ height: '100vh', padding: '80px 0 10px 0' }}>
            <h1 style={{ paddingLeft: '10px' }}>Usage: Users</h1>
            <RequestsUserTable />
        </div>
    )
}

function RequestsUserTable() {
    const navigate = useNavigate()
    const { data, error, isLoading } = useSWR(`/api/admin/userlist`, fetcher)

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription',
        },
        {
            title: 'Allocated Requests',
            dataIndex: 'allocatedRequests',
            key: 'allocatedRequests',
        },
        {
            title: 'Requests Used',
            dataIndex: 'requestsUsed',
            key: 'requestsUsed',
        },
        {
            title: 'Tokens Used',
            dataIndex: 'tokensUsed',
            key: 'tokensUsed',
        },
        {
            title: 'LLM Type',
            dataIndex: 'llmType',
            key: 'llmType',
        },
        {
            title: 'Monthly Subscription cost',
            dataIndex: 'monthlySubscriptionCost',
            key: 'monthlySubscriptionCost',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={() => navigate(`/admin/requestlog/${record.user_id}`)}
                >
                    Requests
                </Button>
            ),
        },
    ]

    if (error)
        return <Alert message="Error loading User List" type="error" showIcon />

    return (
        <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            rowKey="user_id"
        />
    )
}

export function RequestLogDetails() {
    // get from URL params
    const { userId } = useParams()

    return (
        <div style={{ height: '100vh', padding: '80px 0 10px 0' }}>
            <RequestLogDetailsTable userId={userId} />
        </div>
    )
}

export function RequestLogDetailsTable({ userId }) {
    const { data, error, isLoading } = useSWR(
        `/api/admin/requests/${userId}`,
        fetcher,
    )

    if (error)
        return <Alert message="Error loading Request Log Details" type="error" showIcon />

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                const date = new Date(record.createdAt)
                return date.toLocaleString()
            },
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Response',
            dataIndex: 'response',
            key: 'response',
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Completion Tokens',
            dataIndex: 'completionTokens',
            key: 'completionTokens',
        },
        {
            title: 'Prompt Tokens',
            dataIndex: 'promptTokens',
            key: 'promptTokens',
        },
        {
            title: 'Total Tokens',
            dataIndex: 'totalTokens',
            key: 'totalTokens',
        },
        {
            title: 'API Cost',
            dataIndex: 'apiCost',
            key: 'apiCost',
            render: (text, record) => {
                // strip trailing 0s
                const cost = record.apiCost.replace(/0+$/, '')
                return `$${cost}`
            },
        },
    ]

    return (
        <>
            <div style={{ paddingLeft: '10px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Usage Details:
                        {' '}
                        {data?.user.email}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Space direction="vertical" />
            <Table
                dataSource={data?.logs}
                columns={columns}
                expandable={{
                    expandedRowRender:
                                record =>
                                    <p style={{ margin: 0 }}>{record.prompt}</p>,
                }}
                rowKey="id"
                loading={isLoading}
            >

            </Table>
        </>
    )
}
