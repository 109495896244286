import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JK_LOGO from '../../assets/JK.png';
import chatUser from '../../assets/chat-user.png';
import fileUpload from '../../assets/fileUpload.png';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios'
import bot from '../../assets/chat-bot.png';
import './Chat.styles.css';
import { Button, Input, Upload } from 'antd';
import CustomChatBotWidget from './ChatWidget';
import { secureApi } from '../../Config/api';
import { POST, GET } from '../../utils/apis';
import { errorMessage, successMessage } from '../../utils/helpers';
import { allPaths } from '../../utils/constants';

const emptyQuestion = { question: '', answer: '' };

const Chat = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.authReducer?.user)
  const [toggleLeft, setToggleLeft] = useState(1);
  const [logo, setLogo] = useState(null);
  const [themeColor, setThemeColor] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [files, setFiles] = useState([])
  const [fileListString, setFileListString] = useState([])
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('')
  const [instructions, setInstructions] = useState('')
  const [customQuestions, setCustomQuestions] = useState([{ ...emptyQuestion }]);
  const [color, setColor] = useState('black')
  const [uploaded, setUploaded] = useState(false)
  const [botName, setBotName] = useState('Chat Builder')
  const [configId, setConfigId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false)
  const maxSize = 2; // in MB
  const maxFileSize = maxSize * 1024 * 1024;

  const [searchParams, setSearchParams] = useSearchParams();

    const success = searchParams.get("success")

    useEffect(()=>{
      if(success) {
        successMessage("Payment successful")
    }
    },[])

  useEffect(() => {
    axios.get(`${GET?.CONFIGURATION}?user_id=${user?.user_id}`)
      // axios.post(POST?.OPENAI_CHAT, obj)
      .then((resp) => {
        const { data } = resp
        // console.log("data", resp)
        setColor(data?.color)
        setThemeColor(data?.color)
        setBotName(data?.name)
        setName(data?.name)
        setInstructions(data?.instructions)
        setImageUrl(data?.logo)
        setConfigId(data?.config_id)
        // setFileList(data?.logo)
        let parsedQ = data?.questions && data?.questions?.map((a, b) => {
          return (
            JSON.parse(a)
          )
        })
        // console.log(JSON.parse(data?.questions))
        setCustomQuestions([...parsedQ])

        const resultArray = data?.file_list?.length ? data?.file_list?.trim().replace(/,+$/, '')?.split(',') : [];
        setFileListString(resultArray);

      })
      .catch((err) => {
        console.log('err message', err);
      })
  }, [])

  useEffect(() => {
    if (!user) {
      navigate(allPaths?.LOGIN)
    }
  }, [])

  useEffect(() => {
    if (uploaded || isDeleted) {
      axios.get(`${GET?.CONFIGURATION}?user_id=${user?.user_id}`)
        // axios.post(POST?.OPENAI_CHAT, obj)
        .then((resp) => {
          // console.log("I RAAAAN", resp?.data)
          const { data } = resp
          setColor(data?.color)
          setBotName(data?.name)
          setColor(data?.color)
          setThemeColor(data?.color)
          setBotName(data?.name)
          setName(data?.name)
          setInstructions(data?.instructions)
          setImageUrl(data?.logo)
          setConfigId(data?.config_id)
          let parsedQ = data?.questions?.map((a, b) => {
            return (
              JSON.parse(a)
            )
          })
          // console.log(JSON.parse(data?.questions))
          setCustomQuestions([...parsedQ])

          const resultArray = data?.file_list?.length ? data?.file_list?.trim().replace(/,+$/, '')?.split(',') : [];
          // console.log("RESULT ARRAY", resultArray)
          setFileListString(resultArray);
        })
        .catch((err) => {
          console.log('err message', err);
        })
    }
  }, [uploaded, isDeleted])

  const onChangeLogo = (e) => {
    if (e.target.files[0]) {
      setLogoFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setLogo(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (file) => {
    getBase64(file, (url) => {
      setLoading(false);
      setLogo(file)
      setImageUrl(url);
    });
    return false;
  };

  const uploadButton = (
    <div className='upload-logo'>
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
      </button>
    </div>
  );

  const onUploadFiles = file => {
    if (file?.size > maxFileSize) {
      errorMessage("Max file size allowed is 2 MB");
    } else if (fileListString.length < 5) {
      !fileListString.includes(file.name) && setFileListString([...fileListString, file?.name])
      setFiles([...files, file]);
    }
    else {
      errorMessage("Max 5 files allowed")
    }
  };

  const addCustomQuestion = () => {
    setCustomQuestions([...customQuestions, { ...emptyQuestion }]);
  };

  const removeCustomQuestion = (i) => {
    const newQuestions = customQuestions.filter((ques, index) => i !== index)
    setCustomQuestions([...newQuestions]);
  };

  const setQuestion = (value, index) => {
    customQuestions[index].question = value
    setCustomQuestions([...customQuestions]);
  }

  const setAnswer = (value, index) => {
    customQuestions[index].answer = value
    setCustomQuestions([...customQuestions]);
  }

  const handleSave = () => {
    setUploaded(false)
    if (!themeColor || !name || !instructions || !customQuestions?.length) {
      return errorMessage("Missing required fields")
    }
    let formData = new FormData()
    formData.append('user_id', user?.user_id)
    formData.append('logo', imageUrl)
    formData.append('color', themeColor)
    formData.append('name', name)
    formData.append('instructions', instructions)
    formData.append('questions', JSON.stringify(customQuestions))
    // formData.append('files', files || null)
    for (let i = 0; i < files?.length; i++) {
      formData.append('files', files[i]);
    }
    secureApi.post(POST.ADD_CONFIGURATIONS, formData)
      .then((data) => {
        if (data?.success) {
          successMessage("Configurations added successfully")
          setUploaded(true)
        }
      })
      .catch((e) => {
        console.log("Error in generating configurations", e)
        errorMessage("Error in generating configurations")
      })
  }

  const gotoIframe = () => {
    navigate(allPaths?.IFrame)
  }

  const handleDelete = (fileName) => {
    setIsDeleted(false)
    const updated_file_list = fileListString?.filter(file => file !== fileName)?.join(',')
    secureApi.post(POST.DELETE_FILES, { user_id: user?.user_id, file_name: fileName, updated_file_list, config_id: configId })
      .then((data) => {
        if (data?.success) {
          successMessage("File Deleted successfully")
          setIsDeleted(true)
        }
      })
      .catch((e) => {
        console.log("Error in deleting configurations", e)
        errorMessage("Error in deleting configurations")
      })
  }

  return (
    <div className="main_container">
      <div className="chat-header">
        <div className="back-button">
          <div className="header-back-button">
            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </div>
        <div className="header-image">
          <img src={JK_LOGO} className="JK_logo" />
        </div>
        <div className="header-buttons">
          <div className="header-back-button">
            <Button>Come back later</Button>
          </div>
          <div className="header-save-button">
            <Button htmlType="submit" onClick={gotoIframe}>Save</Button>
          </div>
        </div>
      </div>
      <div className="chat-container">
        <div className="chat-left-column">
          <div className="configure-tab">
            <div>
              {/* <span
                onClick={() => {
                  setToggleLeft(0);
                }}
                className={
                  toggleLeft === 0
                    ? 'configure-button-active'
                    : 'configure-button'
                }
              >
                Create
              </span> */}
              <span
                onClick={() => {
                  setToggleLeft(1);
                }}
                className={
                  toggleLeft === 1
                    ? 'configure-button-active'
                    : 'configure-button'
                }
              >
                Configure
              </span>
            </div>
          </div>
          {toggleLeft === 0 ? (
            <>
              <span className="gradient business-text">BUSINESSES</span>
              <div className="chat-row" style={{ marginTop: 0 }}>
                <div className="chat-row-left">
                  <img className="chat_card_image" src={bot} />
                </div>
                <div className="chat-row-right">
                  <h5>{botName}</h5>
                  <p className="tag_line_card">
                    Enhanced version of GPT-3 with superior language
                    capabilities, ideal for sophisticated AI applications and
                    creative content generation.
                  </p>
                </div>
              </div>
              <div className="chat-row">
                <div className="chat-row-left">
                  <img className="chat_card_image" src={chatUser} />
                </div>
                <div className="chat-row-right">
                  <h5>You</h5>
                  <p className="tag_line_card">
                    Third-generation AI language model by OpenAI, generate
                    human-like text for tasks like content creation, customer
                    service, and language translation.
                  </p>
                </div>
              </div>
              <div className="chat-row">
                <div className="chat-row-left">
                  <img className="chat_card_image" src={bot} />
                </div>
                <div className="chat-row-right">
                  <h5>{botName}</h5>
                  <p className="tag_line_card">
                    Third-generation AI language model by OpenAI, generate
                    human-like text for tasks like content creation, customer
                    service, and language translation.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="configure-chat-container">
              <div className="input-container">
                <span className="logo-text">Select Image/Avatar for Your App</span>
                <Upload
                  maxCount={5}
                  name="avatar"
                  listType="picture-circle"
                  className=""
                  showUploadList={false}
                  // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  beforeUpload={handleChange}
                // onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <div className="input-container">
                <span className="logo-text">Select Color for Your App</span>
                <div className="theme-color">
                  <Input
                    className="theme-input"
                    type="color"
                    placeholder="color"
                    value={themeColor || '#ffffff'}
                    onChange={(e) => setThemeColor(e.target.value)}
                  />
                  <div
                    style={{
                      backgroundColor: themeColor,
                      width: '54px',
                      height: '54px',
                      borderRadius: '8px',
                    }}
                  ></div>
                </div>
              </div>
              <div className="input-container">
                <span className="logo-text"> Give Your App a Name</span>
                <Input
                  required
                  className=""
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-container">
                <span className="logo-text">Describe Your App's Job 
                <a className='prompt-link' href="https://www.coursera.org/articles/what-is-prompt-engineering">This is your LLM prompt</a>
                </span>
                 
                {/* \[include pop up text link to a page on prompt engineering\] */}
                <Input
                  required
                  className=""
                  type="text"
                  placeholder="Instructions"
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                />
              </div>
              <div className="theme-color">
                <span className="logo-text">Add specific questions with specific responses (optional)</span>
                <div
                  className="add-question-btn"
                  onClick={() => addCustomQuestion()}
                >
                  Add New Query
                </div>
              </div>
              {customQuestions?.length > 0 &&
                customQuestions?.map((ques, index) => (
                  <div
                    className="input-container"
                    style={{ marginTop: 2 }}
                    key={index}
                  >
                    <Input
                      required
                      style={{ width: '89.9%', margin: '3px 0' }}
                      type="text"
                      value={ques.question}
                      placeholder="Question"
                      onChange={(e) => setQuestion(e.target.value, index)}
                    />

                    <div style={{ display: 'flex', alignItems: 'end' }}>
                      <Input
                        required
                        style={{ width: '90%', margin: '3px 0' }}
                        type="text"
                        value={ques.answer}
                        placeholder="Answer"
                        onChange={(e) => setAnswer(e.target.value, index)}
                      />
                      <div
                        className="remove-question-btn"
                        onClick={() => removeCustomQuestion(index)}
                      >
                        Remove Query
                      </div>
                    </div>
                  </div>
                ))}
              <div className="input-container">
                <span className="logo-text">Add knowledge to your app by uploading files</span>
                <p>
                Upload PDF, TXT, or DOC files to train your AI.{' '}
                </p>
                {/* {!!files?.length && (
                  <div className="file-preview-container">
                    {files?.map((file) => (
                      <div className="file-preview" key={file?.name}>
                        <img src={fileUpload} />
                        <div className="file-preview-name">
                          <span>{file?.name}</span>
                          <span
                            style={{
                              fontWeight: '300',
                              fontSize: '10px',
                              color: '#878787',
                            }}
                          >
                            Document
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )} */}
                {!!fileListString?.length && (
                  <div className="file-preview-container">
                    {fileListString?.map((file) => (
                      <div className="file-preview" key={file}>
                        <div className='delete-btn' onClick={() => handleDelete(file)}>X</div>
                        <img src={fileUpload} />
                        <div className="file-preview-name">
                          <span>{file}</span>
                          <span
                            style={{
                              fontWeight: '300',
                              fontSize: '10px',
                              color: '#878787',
                            }}
                          >
                            Document
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div style={{ marginTop: '10px', display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Upload
                    maxCount={5}
                    multiple={true}
                    beforeUpload={onUploadFiles}
                    showUploadList={false}
                    accept="text/plain, application/pdf"
                  // accept="application/msword, text/plain, application/pdf"
                  >
                    <Button>Upload file</Button>
                  </Upload>
                  <Button onClick={handleSave}>Save Configurations</Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="chat-right-column">
          <div className="preview-header">
            <h2 className="gradient">Conversation Interface</h2>
          </div>
          <CustomChatBotWidget toggleLeft={toggleLeft} uploaded={uploaded} />
        </div>
      </div>
    </div>
  );
};

export default Chat;
