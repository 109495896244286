import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes, redirect, useNavigate } from 'react-router-dom'
import { AIModels, Admin, Chat, Chatbot, Faqs, Home, IFrame, Login, Pricing, Reset, Signup, TermsOfService, UseCases,AllModelsPrices } from '../Screens'
import { RequestLogDetails } from '../Screens/Admin/Admin'
import StickyHeader from '../Components/Header'
import Usage from '../Screens/Usage/Usage'
import allPaths from './paths'
import OTPVerification from '../Screens/OTPVerification/OTPVerification'

function Page404(props) {
    const navigate = useNavigate()
    return (
        <div className="main_container" style={{ height: '100vh', padding: '80px 1em 10px 1em' }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={(
                    <Button
                        type="primary"
                        className="form-button"
                        onClick={() => navigate(allPaths?.HOME)}
                    >
                        Back Home
                    </Button>
                )}
            />
        </div>
    )
}

function withAuth(Component) {
    return function WrappedComponent(props) {
        const user = useSelector(state => state?.authReducer?.user)
        const navigate = useNavigate()
        useEffect(() => {
            if (!user)
                navigate(allPaths.LOGIN)
        })
        if (!user)
            return <div></div>

        return <Component {...props} />
    }
}

function WrapComponent({ component, isChat, ...props }) {
    const Component = component
    const dispatch = useDispatch()

    return (
        <div className="main_container">
            {!isChat && <StickyHeader />}
            <Component {...props} dispatch={dispatch} />
        </div>
    )
}

function AllRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={allPaths.ADMIN} element={<WrapComponent component={withAuth(Admin)} />} />
                <Route path="/admin/requestlog/:userId" element={<WrapComponent component={withAuth(RequestLogDetails)} />} />
                {/* <Route path={allPaths.ADMIN} element={<WrapComponent component={Admin} />} /> */}
                <Route path={allPaths?.LOGIN} element={<Login />} />
                <Route path={allPaths?.OTPVERIFICATION} element={<OTPVerification />} />
                <Route path={allPaths?.TOS} element={<TermsOfService/>} />
                <Route path={allPaths?.SIGNUP} element={<Signup />} />
                <Route path={allPaths?.RESET} element={<Reset />} />
                <Route path={allPaths?.AllModelsPrices} element={<AllModelsPrices />} />
                <Route path={allPaths?.HOME} element={<WrapComponent component={Home} />} />
                <Route path={allPaths?.PRICING} element={<WrapComponent component={Pricing} />} />
                <Route path={allPaths?.AIMODELS} element={<WrapComponent component={AIModels} />} />
                <Route path={allPaths?.IFrame} element={<WrapComponent component={IFrame} />} />
                <Route path={allPaths?.CHAT} element={<WrapComponent component={Chat} isChat />} />
                <Route path={allPaths?.USECASES} element={<UseCases />} />
                <Route path={allPaths?.FAQ} element={<Faqs />} />
                {/* <Route path={allPaths?.CHATBOT} element={<Chatbot />} /> */}
                <Route path={allPaths?.CHATBOT} element={<Chatbot />} />
                <Route path={allPaths.USAGE} element={<WrapComponent component={withAuth(Usage)} />} />
                <Route path="/:page404" element={<WrapComponent component={Page404} />} />
            </Routes>
        </BrowserRouter>
    )
}

export {
    AllRoutes,
    Page404,
}
