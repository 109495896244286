import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./Header.css";

import { allPaths } from "../utils/constants";
import { loginUser } from "../Redux/actions/authActions";
import { useEffect, useState } from "react";
import CancelSubscriptionModal from "./CancelSubscriptionModal/CancelSubscriptionModal";

function useUser() {
  return useSelector((state) => state?.authReducer?.user);
}

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const user = useUser();
  const userId = user?.user_id;

  const handleLogout = () => {
    dispatch(loginUser(null));
    navigate(allPaths.LOGIN);
  };


  const handleUnsubscribe = async () => {
    setOpenSubscriptionModal(true);
   
  };
  const onConfirmCancelSubscription = async () => {
    setConfirmLoading(true)
    try {
      const response = await fetch("/api/cancel-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: user.email }),
      });
      const data = await response.json();
      console.log("DATA", data);
      if (data.status == 200) {
        setConfirmLoading(false)
        setOpenSubscriptionModal(false)
        checkSubscription()
        // alert("Subscription cancelled successfully");
      } else {
        // alert("Subscription not found");
        setConfirmLoading(false)
        setOpenSubscriptionModal(false)

      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
    }
  }

  const checkSubscription = async () => {
    console.log("here in check subscription")
    try {
      const response = await fetch(`/api/check-subscription/${user.email}`, {
        method: "GET",
        
      });
      const data = await response.json();
      // console.log("DATA", data);
      if (data.status == 200) {
        setHasSubscription(true)
        // alert("Subscription cancelled successfully");
      } else {
        setHasSubscription(false)
        // alert("Subscription not found");
      }
    } catch (error) {
      console.error("Error checking subscription:", error);
    }
  }
  useEffect(() => {
    checkSubscription()
  }  , []);

  console.log("herelllllll")
  return (
    <>
    <div className="sticky-header">
      <h1>
        <a href="/" style={{ color: "inherit", textDecoration: "none" }}>
          JakMet
        </a>
      </h1>
      {userId ? (
        <div style={{ display: "flex", gap: "2rem" }}>
          {user.role === "admin" ? <a href={allPaths.ADMIN}>Admin</a> : null}
          <a href={allPaths.USAGE}>Account</a>
         {hasSubscription && <a onClick={handleUnsubscribe} href="#">
            Cancel Subscription
          </a>
}
          <a onClick={handleLogout} href="#">
            Logout
          </a>
        </div>
      ) : (
        <a
          href="/login"
          style={{ flex: 1, textAlign: "right", paddingRight: "1em" }}
        >
          Signup / Login
        </a>
      )}
     
    </div>
     {
      openSubscriptionModal && <CancelSubscriptionModal open={openSubscriptionModal} setOpen={setOpenSubscriptionModal} onConfirm={onConfirmCancelSubscription} confirmLoading={confirmLoading}/>
    }
    </>
  );
}

export default Header;
