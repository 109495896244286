// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .ant-modal-content .ant-modal-footer{
     border-top: none;
}
.ant-modal-content .ant-modal-header{
    border-bottom: none;
}
.ant-modal-content .ant-modal-title{
    font-size: 18px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Components/CancelSubscriptionModal/modal.css"],"names":[],"mappings":"CAAC;KACI,gBAAgB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[" .ant-modal-content .ant-modal-footer{\n     border-top: none;\n}\n.ant-modal-content .ant-modal-header{\n    border-bottom: none;\n}\n.ant-modal-content .ant-modal-title{\n    font-size: 18px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
