// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    width: 200px; 
    margin: 0 auto 20px; 
    display: block; 

  }

.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .terms-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .terms-container p {
    margin-bottom: 15px;
  }
  
  .terms-container ul {
    margin-bottom: 15px;
    margin-left: 20px;
  }
  
  .terms-container li {
    margin-bottom: 5px;
  }
  
  .terms-container a {
    color: #007bff;
  }

  
  .welcome {
    font-family: "Proxima Soft", sans-serif !important;
    font-size: 56px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0px;
    text-align: center;
    background: linear-gradient(93.04deg, #4273b8 4.29%, #4db748 92.42%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/Screens/Tos/tos.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,cAAc;;EAEhB;;AAEF;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;;EAGA;IACE,kDAAkD;IAClD,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,oEAAoE;IACpE,wCAAwC;IACxC,gCAAgC;IAChC,6BAA6B;IAC7B,UAAU;AACd","sourcesContent":[".logo {\n    width: 200px; \n    margin: 0 auto 20px; \n    display: block; \n\n  }\n\n.terms-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .terms-container h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .terms-container h2 {\n    font-size: 20px;\n    margin-top: 30px;\n    margin-bottom: 10px;\n  }\n  \n  .terms-container p {\n    margin-bottom: 15px;\n  }\n  \n  .terms-container ul {\n    margin-bottom: 15px;\n    margin-left: 20px;\n  }\n  \n  .terms-container li {\n    margin-bottom: 5px;\n  }\n  \n  .terms-container a {\n    color: #007bff;\n  }\n\n  \n  .welcome {\n    font-family: \"Proxima Soft\", sans-serif !important;\n    font-size: 56px;\n    font-weight: 700;\n    line-height: 80px;\n    letter-spacing: 0px;\n    text-align: center;\n    background: linear-gradient(93.04deg, #4273b8 4.29%, #4db748 92.42%);\n    -webkit-background-clip: text !important;\n    background-clip: text !important;\n    color: transparent !important;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
