// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/green.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


  .pincodeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media (min-width: 992px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  .pinInput {
    gap: 10px;
    
    input {
      height: 56px !important;
      width: 100% !important;
      padding: 12px 12px 12px 12px;
      border-radius: 4px;
      border: 1.5px solid #2A7BE4;
      background-color: #EDF2F8;
      margin-bottom: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
      margin-right: 0px !important;
    }
  }
  
  .pinInput:focus {
    outline: none;
    border-color: #2A7BE4;
  }

  .verifyBtn .ant-btn {
    background: rgba(66, 115, 184, 1) !important;
    padding: 12px 60px !important;
    height: auto !important;
    border-radius: 6px !important;
    color: white !important;
  }
  
  .green-redial{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 100% 40%;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  
  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/Screens/OTPVerification/style.css"],"names":[],"mappings":";;;EAGE;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT;MACE,kBAAkB;MAClB,mBAAmB;IACrB;EACF;EACA;IACE,SAAS;;IAET;MACE,uBAAuB;MACvB,sBAAsB;MACtB,4BAA4B;MAC5B,kBAAkB;MAClB,2BAA2B;MAC3B,yBAAyB;MACzB,mBAAmB;MACnB,kCAAkC;MAClC,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,kBAAkB;MAClB,4BAA4B;IAC9B;EACF;;EAEA;IACE,aAAa;IACb,qBAAqB;EACvB;;EAEA;IACE,4CAA4C;IAC5C,6BAA6B;IAC7B,uBAAuB;IACvB,6BAA6B;IAC7B,uBAAuB;EACzB;;EAEA;IACE,yDAA+C;IAC/C,yBAAyB;IACzB,2BAA2B;IAC3B,4BAA4B;EAC9B","sourcesContent":["\n\n\n  .pincodeContainer {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    @media (min-width: 992px) {\n      padding-left: 3rem;\n      padding-right: 3rem;\n    }\n  }\n  .pinInput {\n    gap: 10px;\n    \n    input {\n      height: 56px !important;\n      width: 100% !important;\n      padding: 12px 12px 12px 12px;\n      border-radius: 4px;\n      border: 1.5px solid #2A7BE4;\n      background-color: #EDF2F8;\n      margin-bottom: 10px;\n      font-family: 'Poppins', sans-serif;\n      font-size: 24px;\n      font-weight: 700;\n      line-height: 40px;\n      text-align: center;\n      margin-right: 0px !important;\n    }\n  }\n  \n  .pinInput:focus {\n    outline: none;\n    border-color: #2A7BE4;\n  }\n\n  .verifyBtn .ant-btn {\n    background: rgba(66, 115, 184, 1) !important;\n    padding: 12px 60px !important;\n    height: auto !important;\n    border-radius: 6px !important;\n    color: white !important;\n  }\n  \n  .green-redial{\n    background-image: url(\"../../assets/green.png\");\n    background-size: 100% 40%;\n    background-position: bottom;\n    background-repeat: no-repeat;\n  }\n  \n  \n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
