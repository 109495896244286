import React,{useState} from 'react'
import OTPInput from 'otp-input-react'
import { LuShieldCheck } from "react-icons/lu"
import { RiCheckboxMultipleLine, RiWalletLine } from "react-icons/ri"
import AI_ANALYSIS from '../../assets/Ai_analysis.png'
import JK_LOGO from '../../assets/JK.png'
import { allPaths } from '../../utils/constants'
import { FaFacebook } from "react-icons/fa";
import Button from 'antd/lib/button'
import './style.css'
import axios from "axios"
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import { useLocation, useNavigate } from 'react-router-dom'



const OTPVerification = () => {
    const [otp, setOtp] = useState();
    const {state} = useLocation();
    const navigate = useNavigate()


    const handleResendOTP = async () =>{
        
        try {
            const result = await axios.post(POST?.RESEND_OTP,{
                email:state.email
               })
               successMessage("OTP has been sent to your email address!")
        } catch (error) {
            errorMessage("Error in sendig OTP")
        }

    }

    

    const onSubmit = async () => {
        console.log(state.email)

        try{
           const result = await axios.post(POST?.VERIFY_OTP,{
            otp,
            email:state.email
           })
           successMessage("Your OTP has been verified successfully!")
           navigate(allPaths?.LOGIN)
           
        }
        catch(err){
          errorMessage(err?.response?.data || 'Something Went Wrong!')
        }
    }

    const onFinishFailed = (err) => {
        // errorMessage()
    }

    return (
    // <div className='pincodeContainer'>
    //     <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure className={'pinInput'} />
    // </div>
    <div className='main_container'>
            <div className="two-col-container">
                <div className="layout">
                    <div className="col col-main left">
                        <div className="blue-grad"></div>
                        <div className="radial-lines"></div>
                        <div className="green-grad"></div>
                        <img src={JK_LOGO} className='JK_logo' />
                        <p className='gradient tag_line'>
                            Empower Your Vision with Intelligence.
                        </p>
                        <p className='label_head'>
                        Create your own Generative AI App Instantly
                        </p>
                        <p className='label_desc'>
                        Spin up a trainable, shareable AI website with the LLM of your choice - GPT-4, Open source Llama, Gemini, Claude.
            No code required, zero charge for hosting or setup, pay only for your API usage.
                        </p>
                        <div className='two-column green-redial'>
                            <div className='ai_img'>
                                <img src={AI_ANALYSIS} />
                            </div>
                            <div className='right_ls'>
                                <div>
                                    <span className='single_ls'>
                                        <RiWalletLine className='login_icons' />
                                        <p className='ls_p1'>
                                        Fine-tune to your heart's content
                                        </p>
                                    </span>
                                    <p className='ls_p2'>
                                        - Freely configure instructions and prompts, securely upload training data to free cloud storage.
                                    </p>

                                </div>
                                <div>
                                    <span className='single_ls'>
                                        <LuShieldCheck className='login_icons' />
                                        <p className='ls_p1'>
                                            Privacy-First Architecture
                                        </p>
                                    </span>
                                    <p className='ls_p2'>
                                        - Avoid vendor lock-in with app stores, keep control over your data and publish your AI app whenever, wherever.
                                    </p>
                                </div>
                                <div>
                                    <span className='single_ls'>
                                        <RiCheckboxMultipleLine className='login_icons' />
                                        <p className='ls_p1'>
                                            Scale with Confidence
                                        </p>
                                    </span>
                                    <p className='ls_p2'>
                                        - We'll handle the SSL certs, network bandwidth, and firewalls with state of the art devops automation, you just focus on making a cool app.
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "center", marginTop: "50px", zIndex: 100000, fontSize: '20px' }}>
                            Have any questions? <span onClick={() => navigate(allPaths.FAQ)} style={{ color: "#4273B8", cursor: "pointer", zIndex: 111111 }}>See FAQs </span>or check out some <span onClick={() => navigate(allPaths.USECASES)} style={{ color: "#4273B8", cursor: "pointer", zIndex: 111111 }}>Use Cases</span>
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: "50px",
                                    paddingBottom: "50px",
                                    zIndex: 100000,
                                }}
                            >
                                <div>
                                    <a href="https://www.facebook.com/profile.php?id=61555510916560">
                                        <FaFacebook
                                            style={{
                                                width: "45px",
                                                height: "45px",
                                                margin: "10px",
                                                color: "#4273B8",
                                            }}
                                        />
                                    </a>

                                    <a href="https://www.instagram.com/jakmetai/">
                                        <img
                                            style={{
                                                margin: "10px",
                                            }}
                                            src="https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg"
                                            width={40}
                                            height={40}
                                            alt="instagram"
                                        />
                                    </a>
                                    <a href="https://www.tiktok.com/@jakmetai">
                                        <img style={{
                                            margin: "10px",
                                        }}
                                            src="https://static-00.iconduck.com/assets.00/tiktok-icon-891x1024-ozp1clb4.png"
                                            width={40}
                                            height={40}
                                            alt="tiktok"
                                        />
                                    </a>

                                    <a href="https://twitter.com/JakMetAI">
                                        <img
                                            style={{
                                                margin: "10px",
                                            }}
                                            src="https://about.twitter.com/content/dam/about-twitter/x/brand-toolkit/logo-black.png.twimg.1920.png"
                                            width={35}
                                            height={35}
                                            alt="twitter"
                                        />
                                    </a>

                                    <a href="https://www.linkedin.com/company/95683896">
                                        <img
                                            style={{
                                                margin: "10px",
                                            }}
                                            src="https://cdn0.iconfinder.com/data/icons/shift-logotypes/32/Linkedin-128.png"
                                            width={45}
                                            height={45}
                                            alt="linkedin"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    < div className="col col-main right">
                        <div class="background-color"></div>
                        <div class="radial-lines-r"></div>
                        <p className='welcome'>
                           OTP Verification
                        </p>
                        <div style={{ padding: '10px 40px', }}>
                            <div
                                name='Register'
                                hideRequiredMark
                                initialValues={{
                                    remember: true,
                                    otp:""
                                }}
                                layout='vertical'
                                encType='multipart/form-data'
                                onFinish={onSubmit}
                                onFinishFailed={onFinishFailed}
                                // ref={(e) => setForm(e)}
                                data-testid={'signup-form'}
                                style={{display:'grid',justifyItems:'center'}}
                            >
                             <div className='pincodeContainer' style={{zIndex:'99'}}>
                                 <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false}  className={'pinInput'} />
                            </div>
                            
                            <div className='verifyBtn'>
                            <Button onClick={onSubmit} style={{ width: 'fit-content',padding:'2px 20px', marginTop:'20px' }} >
                                 Submit
                            </Button>
                            </div>
                            <p  onClick={handleResendOTP} style={{cursor:'pointer', zIndex:'99', pointerEvents:'auto', color:'rgba(66, 115, 184, 1)', marginTop:'15px'}}>
                                Resend OTP
                            </p>    
                                                       
                            </div>

                           
                            {/* <div className='signin signInFull'>
                                <Button
                                    onClick={handleButtonClick}
                                    style={{ width: '100%' }} >
                                    Contact us
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
      
    );
}

export default OTPVerification