import React, { useEffect, useState } from 'react';
import JK_LOGO from '../../assets/JK.png';
import { modelData } from './staticModelsData';
import './AllModelsPrices.style.css';

function AllModelsPrices() {


   
    // const getFilteredProducts = (modelName) => {
    //     return products.filter(product => product.name.toLowerCase().includes(modelName));
    // };

    const getJustifyContentStyle = (productsCount) => {
        return productsCount === 1 ? 'flex-start' : 'center';
    };

    return (
        <div className="main_container" style={{ height: '100vh', padding: '60px 0 10px 0' }}>
            <div className="">
                <div className="blue-grad-L"></div>
                <div className="radial-lines-card"></div>
                <div className="dots-card"></div>
                <div className="dots-card-2"></div>
                <div className="green-grad-R"></div>
                <div className="faq-center_cont">
                    <img src={JK_LOGO} className="JK_logo" alt="Logo" />
                    <p className="welcome">Pricing</p>
                    {modelData.map((model, index) => (
                        <div style={{ width: '90%' }} key={index}>
                            <div className="modellogoname-row">
                                <img className="models_card_image" src={model.src} alt={model.title} />
                                <h4 className='models_card_title'>{model.title}</h4>
                            </div>
                            <div 
                                className='price-cards-container' 
                                style={{ justifyContent: getJustifyContentStyle(model.products.length) }}
                            >
                                {model.products.map((product) => (
                                    <div key={product.id} className="price-card">
                                        <div className="price-top">
                                            <h3 className='product-title'>{product.name}</h3>
                                            <p className='price_tag_line_card'>{product.description}</p> 
                                        </div>
                                        <div className='price-bottom'>
                                            <h2 className='gradient'>
                                                ${product.price}/month
                                            </h2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AllModelsPrices;
