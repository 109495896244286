import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RiSendPlaneLine } from 'react-icons/ri'
import { BiDislike, BiEditAlt, BiLike, BiRefresh } from 'react-icons/bi'
import { Button, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useSearchParams } from 'react-router-dom'
import { errorMessage } from '../../utils/helpers'
import { GET, POST } from '../../utils/apis'
import ChatEmpty from '../../assets/ChatEmpty.png'
import chatUser from '../../assets/chat-user.png'
import bot from '../../assets/chat-bot.png'
import like from '../../assets/like-icon.png'
import './ChatWidget.styles.css'

function CustomChatBotWidget({ toggleLeft, fullHeight, uploaded, isIframe }) {
    const user = useSelector(state => state?.authReducer?.user)
    const [searchParams] = useSearchParams()

    const [question, setQuestion] = useState('')
    const [loading, setLoading] = useState(false)
    const containerRef = useRef(null)
    const [messages, setMessages] = useState([])
    const [showLottie, setShowLottie] = useState(true)
    const [likes, setLikes] = useState([])
    const [dislikes, setDisLikes] = useState([])
    const [regenerateValue, setRegenerateValue] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [isModalOpen, setisModalOpen] = useState(false)
    const [color, setColor] = useState('#424242')
    const [logo, setLogo] = useState(null)
    const [botName, setName] = useState('Chat Builder')

    const chatId = searchParams.get('chat_id')

    const userId = isIframe ? chatId : user?.user_id

    console.log({ messages })
    useEffect(() => {
        axios.get(`${GET?.CONFIGURATION}?user_id=${userId}`)
            .then((resp) => {
                const { data } = resp
                setColor(data?.color)
                setName(data?.name || botName)
                setLogo(data?.logo)
            })
            .catch((err) => {
                console.log('err message', err)
            })
    }, [])

    useEffect(() => {
        if (uploaded) {
            axios.get(`${GET?.CONFIGURATION}?user_id=${userId}`)
                .then((resp) => {
                    const { data } = resp
                    setColor(data?.color)
                    setName(data?.name)
                    setLogo(data?.logo)
                })
                .catch((err) => {
                    console.log('err message', err)
                })
        }
    }, [uploaded])

    const handleNewUserMessage = () => {
        setShowLottie(false)
        if (!question)
            return

        setQuestion('')
        setLoading(true)
        const obj = {
            user_id: userId,
            question,
        }
        const newData = [...messages, { type: 'q', message: question }]
        setMessages(newData)
        axios.post(POST?.LANGCHAIN_CHAT, obj)
        // axios.post(POST?.OPENAI_CHAT, obj)
            .then((data) => {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'a', message: data?.data?.text },
                ])
            })
            .catch((err) => {
                console.log('err message', err)
                const message = err.response?.data?.message
                    ?? 'Error in connecting to our chat server'
                errorMessage(message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const regenerateResposncse = () => {
        setShowLottie(false)
        if (!regenerateValue)
            return

        setRegenerateValue('')
        setLoading(true)
        const obj = {
            user_id: userId,
            question: regenerateValue,
        }
        axios.post(POST?.LANGCHAIN_CHAT, obj)
        // axios.post(POST?.OPENAI_CHAT, obj)
            .then((data) => {
                setMessages((prevDataArray) => {
                    return prevDataArray.map((item, i) => {
                        if (i === currentIndex)
                            return { ...item, message: data.data?.text }

                        return item
                    })
                })
            })
            .catch((err) => {
                console.log('err message', err)
                errorMessage('error in connecting to our chat server')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (regenerateValue)
            regenerateResposncse()
    }, [regenerateValue])

    const handleKeyUp = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()
            handleNewUserMessage(e)
        }
    }

    const onEdit = (message, index) => {
        setQuestion(message?.message)
    }

    const onLike = (index) => {
        if (likes.includes(index))
            return
        setLikes((likes) => {
            return [...likes, index]
        })
        if (dislikes.includes(index))
            setDisLikes(dislikes.filter(disLike => disLike !== index))

        setisModalOpen(true)
    }

    const onDislike = (index) => {
        if (dislikes.includes(index))
            return
        setDisLikes((disLikes) => {
            return [...disLikes, index]
        })
        if (likes.includes(index))
            setLikes(likes.filter(like => like !== index))

        setisModalOpen(true)
    }

    const handleOk = () => {

    }

    const regenerate = (i) => {
        const message = messages.find((message, index) => index === i - 1)?.message
        if (message) {
            setCurrentIndex(i)
            setRegenerateValue(message)
        }
    }

    return (
        <>
            <div className="">
                <div
                    style={{
                        border: '1px solid #fff',
                        marginRight: 5,
                        position: 'relative',
                    }}
                >
                    {showLottie && !messages?.length && (
                        <div className="bot_animation">
                            <img src={ChatEmpty} />
                            <span>{botName || 'Your Bot'}</span>
                        </div>
                    )}
                    <div className="docs_custom_chat_body_div" ref={containerRef} style={{ height: fullHeight && 'calc(100vh - 70px)' }}>
                        {!showLottie && (
                            <>
                                {!!messages?.length && messages?.map((message, index) => (
                                    <div className="chat-row">
                                        <div className="chat-row-left chat-row-icons">
                                            <img className="chat_card_image" src={message?.type === 'q' ? chatUser : logo || bot} />
                                        </div>
                                        <div className="chat-row-right">
                                            <h5
                                                style={{ color: message?.type === 'q' ? 'balck' : color }}
                                            >
                                                {message?.type === 'q' ? 'You' : botName}
                                            </h5>
                                            <p className="tag_line_card">
                                                {message.message}
                                            </p>
                                            <div className="chat-icons">
                                                {message?.type === 'q'
                                                    ? <BiEditAlt onClick={() => onEdit(message, index)} />
                                                    : (
                                                        <>
                                                            {!isIframe && (
                                                                <>
                                                                    <BiLike color={likes.includes(index) ? '#6089C3' : ''} onClick={() => onLike(index)} />
                                                                    <BiDislike color={dislikes.includes(index) ? 'red' : ''} onClick={() => onDislike(index)} />
                                                                </>
                                                            )}
                                                            <BiRefresh onClick={() => regenerate(index)} />
                                                        </>
                                                        )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {loading
                                    ? (
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'self-start' }}>
                                                <div className="docs_custom_chat_bot_avatar">
                                                    <img
                                                        src={logo || bot}
                                                        alt="chatbot"
                                                        style={{ margin: '8px', width: '25px', height: '25px' }}
                                                    />
                                                </div>
                                                <div className="loading">
                                                    <span className="loading__dot"></span>
                                                    <span className="loading__dot"></span>
                                                    <span className="loading__dot"></span>
                                                </div>
                                            </div>
                                        </>
                                        )
                                    : null}
                            </>
                        )}
                    </div>
                    <div className="docs_custom_chat_footer_div">
                        <input
                            disabled={loading}
                            onKeyDown={handleKeyUp}
                            value={question}
                            onChange={e => setQuestion(e?.target?.value)}
                            type="text"
                            className="docs_custom_chat_input"
                            placeholder={`Message ${botName}`}
                        />
                        <div className="send_icon">
                            <RiSendPlaneLine
                                disabled={true}
                                onClick={handleNewUserMessage}
                                className="docs_custom_chat_bot_icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                footer={false}
                closable={false}
                style={{ borderRadius: '8px' }}
                title={(
                    <span>
                        <img src={like} />
                        {' '}
                        Provide additional feedback
                    </span>
                )}
                open={isModalOpen}
                onOk={handleOk}
            >
                <TextArea style={{ borderRadius: '8px', resize: 'none' }} rows={4} placeholder="What do you like about the response?" maxLength={1000} />
                <div className="modal-buttons">
                    <div className="support">
                        <Button onClick={() => setisModalOpen(false)}>Contact Support</Button>
                    </div>
                    <div className="submit">
                        <Button onClick={() => setisModalOpen(false)}>Submit feedback</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CustomChatBotWidget
