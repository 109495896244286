import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import './modal.css';
const CancelSubscriptionModal = ({open,setOpen,  onConfirm,confirmLoading=false}) => {
//   const [open, setOpen] = useState(false);
//   const [confirmLoading, setConfirmLoading] = useState(false);
console.log("confirmLoading",confirmLoading)

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    // setModalText('The modal will be closed after two seconds');
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setOpen(false);
    //   setConfirmLoading(false);
    // }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <div className='cancel-subscription-modal'>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with async logic
      </Button> */}
      <Modal
        title="Cancel Subscription"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        centered
        style={{fontSize:"18px"}}
        footer={[
            <>
            <Button key="back" style={{backgroundColor:"#EEEEEE", borderRadius:"8px", border:"none"}} loading={confirmLoading} onClick={onConfirm}>
              Yes, Cancel 
              </Button>
              <Button style={{backgroundColor:"#4273B8", borderRadius:"8px", border:"none"}}  type="primary" onClick={handleCancel} >Don't Cancel</Button>
              </>
        ]}
      >
       <p style={{color:"#9A9A9A", fontSize:"16px", fontWeight:"500"}}>Are you sure you want cancel your  Subscription?</p>
      </Modal>
    </div>
  );
};

export default CancelSubscriptionModal;