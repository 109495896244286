import React from 'react';
import './tos.styles.css';
import JK_LOGO from '../../assets/JK.png';

const TermsOfService = () => {
  return (
    <div className="terms-container">
         <img src={JK_LOGO} alt="JakMet Logo" className="logo" />
      <h1 className="welcome">Terms of Service (TOS) for JakMet</h1>
      <p>Welcome to JakMet! By accessing or using our platform, you agree to be bound by these Terms of Service and our Privacy Policy. Please read them carefully before using our services.</p>

      <h2>Acceptance of Terms</h2>
      <p>By registering for, accessing, or using any services provided by JakMet, you agree to be bound by these terms and conditions. If you do not agree with any part of the terms, you must not use our services.</p>

      <h2>Description of Service</h2>
      <p>JakMet provides a platform that allows users to create, customize, and deploy artificial intelligence (AI) services. These services can be used across various sectors including, but not limited to, business, education, and individual research.</p>

      <h2>User Obligations</h2>
      <p>You agree to use JakMet's services in compliance with all applicable local, state, national, and international laws, rules, and regulations, and you are solely responsible for all acts or omissions that occur under your account.</p>

      <h2>Prohibited Use</h2>
      <p>You may not use the JakMet platform to engage in, foster, or promote illegal, abusive, or irresponsible behavior including, but not limited to:</p>
      <ul>
        <li>Creating or distributing content that is illegal, harmful, or offensive. This includes, but is not limited to, content that is defamatory, obscene, abusive, invasive of privacy, or otherwise objectionable.</li>
        <li>Promoting or facilitating violence or harmful activities.</li>
        <li>Violating intellectual property rights of others.</li>
        <li>Generating or disseminating false information or content intended to misinform.</li>
        <li>Any other activity that might make JakMet liable for any reason.</li>
      </ul>

      <h2>Intellectual Property</h2>
      <p>You acknowledge that JakMet owns all rights, titles, and interests in and to the service provided, including all intellectual property rights. You agree that you shall not duplicate, disassemble, reverse engineer, or use the service for any purpose that breaches these terms.</p>

      <h2>Data Privacy</h2>
      <p>JakMet is committed to protecting the privacy of its users. Please refer to our Privacy Policy for detailed information on how we collect, use, and disclose information from our users.</p>

      <h2>Termination</h2>
      <p>JakMet may terminate your access to all or any part of the service at any time, with or without cause, with or without notice, effective immediately, for any reason whatsoever, including but not limited to breach of these terms.</p>

      <h2>Liability Limitation</h2>
      <p>JakMet will not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of or inability to use the service.</p>

      <h2>Indemnity</h2>
      <p>You agree to indemnify and hold harmless JakMet and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms of Service, or your violation of any law or the rights of a third party.</p>

      <h2>General Terms</h2>
      <p>These Terms of Service constitute the entire agreement between you and JakMet and govern your use of the service, superseding any prior agreements between you and JakMet. The laws of Texas, USA shall govern the terms without regard to conflicts of laws.</p>

      <h2>Changes to Terms of Service</h2>
      <p>JakMet reserves the right, at its sole discretion, to modify or replace any part of these Terms at any time. It is your responsibility to check these Terms periodically for changes. Your continued use of or access to our services following the posting of any changes to these Terms constitutes acceptance of those changes.</p>

      <h2>Contact Information</h2>
      <p>For any questions about these Terms of Service, please contact us at <a href="mailto:admin@jakmet.ai">admin@jakmet.ai</a>.</p>

      <p >By using JakMet, you are accepting these terms. Please use our platform responsibly.</p>
    </div>
  );
}

export default TermsOfService;