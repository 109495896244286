// data.js
import JK_LOGO from '../../assets/JK.png';
import GPTGreen from '../../assets/chatgpt-green.png';
import GPTPurple from '../../assets/chatgpt-purple.png';
import Claude from '../../assets/Claude.png';
import Llm from '../../assets/llma.png';
import Gemini from '../../assets/gemini.png';

export const modelData = [
  {
    src: Llm,
    title: 'LLaMA 2',
    modelname: 'llama',
    products: [
      {
        id: 1,
        name: 'Llama - Individual - Minimal - 100',
        description: '100 Open source Llama requests per month',
        price: 1,
      },
      {
        id: 2,
        name: 'Llama - Small Business - Minimal - 5000',
        description: '5000 Open source Llama requests per month',
        price: 45,
      },
    ],
  },
  { 
    src: GPTGreen,
    title: 'GPT-3',
    modelname:'gpt-3',
    products: [
    {
      id: 1,
      name: 'GPT-3.5 - Individual - Minimal - 100',
      description: '100 OpenAI GPT-3.5 requests per month',
      price: 10,
    },
    {
      id: 2,
      name: 'GPT-3.5 - Small Business - Minimal - 500',
      description: '500 OpenAI GPT-3.5 requests per month',
      price: 45,
    },
  ], 
 },
  { 
    src: GPTPurple, 
    title: 'GPT-4',
    modelname:'gpt-4',
    products: [
    {
      id: 1,
      name: 'GPT-4 - Individual - Minimal - 100',
      description: '100 OpenAI GPT-4 requests per month',
      price: 25,
    },
    {
      id: 2,
      name: 'GPT-4 - Small Business - Minimal - 500',
      description: '',
      price: 45,
    },
  ], 
 },
  { 
    src: Claude, 
    title: 'Claude',
    modelname:'claude' ,
    products: [
    {
      id: 1,
      name: 'Claude - Individual - Minimal - 100',
      description: '100 Anthropic Claude requests per month',
      price: 9,
    },
    {
      id: 2,
      name: 'Claude - Small Business - Minimal - 500',
      description: '500 Anthropic Claude requests per month',
      price: 45,
    },
  ], 
},
  { 
    src:Gemini, 
    title: 'Gemini',
    modelname:'gemini',
    products: [
    {
      id: 1,
      name: 'Gemini - Individual - Minimal - 100',
      description: '100 Google Gemini requests per month',
      price: 9,
    },
    {
      id: 2,
      name: 'Gemini - Small Business - 500',
      description: '500 Google Gemini requests per month',
      price: 45,
    },
  ], },
];
