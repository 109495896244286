import { Alert, Col, Divider, Progress, Row, Skeleton, Space, Statistic, Table } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { fetcher } from '../../utils/swr'

function Usage() {
    const userId = useSelector(state => state?.authReducer?.user.user_id)

    return (
        <div
            className="main_container"
            style={{ height: '100vh', padding: '80px 1em 10px 1em' }}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <UsageSummary userId={userId} />
                <Divider>Request Log</Divider>
                <UsageDetailsTable userId={userId} />
            </Space>
        </div>
    )
}

function UsageSummary({ userId }) {
    const { data, error, isLoading } = useSWR(`/api/requests/usage`, fetcher)

    if (error) {
        return (
            <Alert
                message="Error loading Usage Summary"
                type="error"
                showIcon
            />
        )
    }

    if (isLoading)
        return <Skeleton active />
    const {
        subscription,
        allocatedRequests,
        usedRequests,
        monthlySubscriptionCost,
        renewedAt,
    } = data

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Statistic
                    title="Your Subscription"
                    value={`${subscription} ($${monthlySubscriptionCost}/mo)`}
                />
            </Col>
            <Col span={6}>
                <Statistic
                    title="Requests"
                    valueRender={
                        () => `${usedRequests} / ${allocatedRequests} used`
                    }
                />
            </Col>
            <Col span={6}>
                <Progress
                    type="circle"
                    percent={(usedRequests / allocatedRequests) * 100}
                />
            </Col>
            <Col span={6}>
                <Statistic
                    title="Resets On"
                    value={new Date(renewedAt).toLocaleDateString()}
                />
            </Col>
        </Row>
    )
}

function UsageDetailsTable({ userId }) {
    const { data, error, isLoading } = useSWR(`/api/requests/logs`, fetcher)

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                const date = new Date(record.createdAt)
                return date.toLocaleString()
            },
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Response',
            dataIndex: 'response',
            key: 'response',
        },
    ]

    if (error) {
        return (
            <Alert
                message="Error loading Usage Details"
                type="error"
                showIcon
            />
        )
    }

    return (
        <Table
            dataSource={data}
            columns={columns}
            rowKey="id"
            loading={isLoading}
        />
    )
}

export default Usage
