import Home from './Home/Home'
import Login from './Login/Login'
import Reset from './Login/Reset'
import Signup from './Login/Signup'
import Pricing from './Pricing/Pricing'
import AIModels from './AIModels/AIModels'
import Chat from './Chat/Chat'
import IFrame from './IFrame/IFrame'
import Chatbot from './Chatbot/Chatbot'
import UseCases from './UseCases/UseCases'
import Faqs from './Faqs/Faqs'
import Admin from './Admin/Admin'
import Usage from './Usage/Usage'
import TermsOfService from './Tos/TermsOfService'
import AllModelsPrices from './AllModelsPrices/AllModelsPrices'

export {
    Admin,
    Home,
    Login,
    Reset,
    Signup,
    Pricing,
    AIModels,
    Chat,
    IFrame,
    Chatbot,
    UseCases,
    Faqs,
    TermsOfService,
    AllModelsPrices
}
