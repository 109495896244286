import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "antd";
import { errorMessage } from "../../utils/helpers";
import { POST } from "../../utils/apis";
import { secureApi } from "../../Config/api";
import "./pricing.styles.css";
import { useLocation } from "react-router-dom";
import JK_LOGO from "../../assets/JK.png";
import { AiOutlineCheck } from "react-icons/ai";
import getSymbolFromCurrency from "currency-symbol-map";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pricing = () => {
  const user = useSelector((state) => state?.authReducer?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [cancel, setCancel] = useState(false);

  const { state } = useLocation();
  // console.log('USER', user)
  const email = user?.email;

  useEffect(() => {
    if (cancel) {
      errorMessage("Payment unsuccessful");
    }
  }, [cancel]);

  useEffect(() => {
    if (!user) {
      navigate(allPaths?.LOGIN);
    } else {
      fetchProducts();
    }
  }, [user]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/products");
      const data = await response.json();
      const filteredProducts = data.products.filter((product) =>
        product.name.toLowerCase().includes(state.model.toLowerCase())
      );
      setProducts(filteredProducts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      errorMessage("Error fetching products");
      setLoading(false);
    }
  };

  const getCheckoutSession = async (product_id) => {
    const stripe = await stripePromise;
    setLoading(true);
    secureApi
      .post(POST.GET_CHEOUT_SESSION, { product_id })
      .then((data) => {
        console.log("SESSION DATA", data);
        if (data?.success) {
          setLoading(false);
          const { session } = data;
          stripe
            .redirectToCheckout({
              sessionId: session.id,
            })
            .then((result) => {
              if (result.error) {
                errorMessage(result.error);
              }
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error in  generating session");
        errorMessage("Error in generating session", e);
      });
  };

  return (
    <div className="main_container">
      <div className="">
        <div className="background-color-card"></div>
        <div className="radial-lines-card"></div>
        <div className="dots-card"></div>
        <div className="dots-card-2"></div>
        <div className="pricing_center_cont">
          <img src={JK_LOGO} className="JK_logo" />
          <p className="welcome">Select Your Pricing Tier</p>
          <div className="price-card-container">
            {products.map((product, index) => {
              const currency = getSymbolFromCurrency(product.price?.currency);
              return (
                <div key={product.id} className="price-card">
                  <div className="price-top">
                    <h3 className="product-name">{product.name}</h3>
                    <p className="price_tag_line_card">{product.description}</p>
                  </div>
                  <div className="price-bottom">
                    {product.price ? (
                      <h2 className="gradient">
                        {currency}
                        {product.price.unit_amount}/
                        {product.price.recurring.interval}
                      </h2>
                    ) : (
                      <h2 key={index} className="gradient">
                        Free
                      </h2>
                    )}
                    {/* <p className="price_tag_line_card">
                      {product.unit ??
                        "Unlimited AI integration for businesses eager to embrace full-scale AI solutions."}
                    </p> */}
                    <div className="signin signInFull">
                      <Button
                        disabled={loading}
                        style={{ width: "100%" }}
                        onClick={() => getCheckoutSession(product.id)}
                      >
                        Get started
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
