const HOME = '/'
const LOGIN = '/login'
const OTPVERIFICATION = '/otp-verification'
const SIGNUP = '/register'
const RESET = '/reset'
const USERS = '/users'
const SINGLE_USER = '/user'
const PRICING = '/pricing'
const AIMODELS = '/models'
const CHAT = '/chat'
const IFrame = '/iframe'
const CHATBOT = '/chatbot'
const USECASES = '/usecases'
const FAQ = '/faq'
const USAGE = '/usage'
const TOS = "/terms-of-service"
const AllModelsPrices="all-models-prices"

export default {
    ACCOUNT: '/account',
    ADMIN: '/admin',
    OTPVERIFICATION,
    HOME,
    LOGIN,
    SIGNUP,
    RESET,
    USERS,
    SINGLE_USER,
    PRICING,
    AIMODELS,
    CHAT,
    CHATBOT,
    IFrame,
    USECASES,
    FAQ,
    USAGE,
    TOS,
    AllModelsPrices
}
