import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { allPaths } from '../../utils/constants'
import JK_LOGO from '../../assets/JK.png'
import GPTGreen from '../../assets/chatgpt-green.png'
import GPTPurple from '../../assets/chatgpt-purple.png'
import Claude from '../../assets/Claude.png'
import Llm from '../../assets/llma.png'
import Gemini from '../../assets/gemini.png'

import './AIModels.style.css'

function AIModels() {
    const user = useSelector(state => state?.authReducer?.user)
    const [aiModel, _setAiModel] = useState('')
    const setAiModel = async (model) => {
        await fetch('/api/set-model', {
            method: 'POST',
            body: JSON.stringify({ model }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        _setAiModel(model)
    }
    const navigate = useNavigate()
    useEffect(() => {
        if (!user)
            navigate(allPaths?.LOGIN)
    }, [user, navigate])

    const backCalling = () => {
        navigate(-1)
    }

    const pricingNavigate = () => {
        navigate(allPaths?.PRICING, {state:{
            model: aiModel
        }})
    }

    return (
        <div className="main_container" style={{ height: '100vh', padding: ' 60px 0 10px 0' }}>
            <div className="">
                <div className="blue-grad-L"></div>
                <div className="radial-lines-card"></div>
                <div className="dots-card"></div>
                <div className="dots-card-2"></div>
                <div className="green-grad-R"></div>
                <div className="faq-center_cont">
                    <img src={JK_LOGO} className="JK_logo" />
                    <p className="welcome">
                        Choose Your AI Model
                    </p>
                    <div style={{ borderColor: aiModel === 'llama' ? '#7EA0CF' : '' }} className="model-row" onClick={() => setAiModel('llama')}>
                        <div className="models-column-left">
                            <img className="models_card_image" src={Llm} />
                        </div>
                        <div className="models-column-right">
                            <h4>LLaMA 2</h4>
                            <p className="tag_line_card">
                                Advanced AI model for academic and research purposes, ideal for in-depth information and complex problem-solving in academic settings.
                            </p>
                        </div>
                    </div>
                    <div style={{ borderColor: aiModel === 'gpt-3' ? '#7EA0CF' : '' }} className="model-row" onClick={() => setAiModel('gpt-3')}>
                        <div className="models-column-left">
                            <img className="models_card_image" src={GPTGreen} />
                        </div>
                        <div className="models-column-right">
                            <h4>GPT-3</h4>
                            <p className="tag_line_card">
                                Enhanced version of GPT-3 with superior language capabilities, ideal for sophisticated AI applications and creative content generation.
                            </p>
                        </div>
                    </div>
                    <div style={{ borderColor: aiModel === 'gpt-4' ? '#7EA0CF' : '' }} className="model-row" onClick={() => setAiModel('gpt-4')}>
                        <div className="models-column-left">
                            <img className="models_card_image" src={GPTPurple} />
                        </div>
                        <div className="models-column-right">
                            <h4>GPT-4</h4>
                            <p className="tag_line_card">
                                Third-generation AI language model by OpenAI, generate human-like text for tasks like content creation, customer service, and language translation.
                            </p>
                        </div>
                    </div>
                    <div style={{ borderColor: aiModel === 'claude' ? '#7EA0CF' : '' }} className="model-row" onClick={() => setAiModel('claude')}>
                        <div className="models-column-left">
                            <img className="models_card_image" src={Claude} />
                        </div>
                        <div className="models-column-right">
                            <h4>Claude</h4>
                            <p className="tag_line_card">
                                Third-generation AI language model by OpenAI, generate human-like text for tasks like content creation, customer service, and language translation.
                            </p>
                        </div>
                    </div>
                    <div style={{ borderColor: aiModel === 'gemini' ? '#7EA0CF' : '' }} className="model-row" onClick={() => setAiModel('gemini')}>
                        <div className="models-column-left">
                            <img className="models_card_image" src={Gemini} />
                        </div>
                        <div className="models-column-right">
                            <h4>Gemini</h4>
                            <p className="tag_line_card">
                                Third-generation AI language model by OpenAI, generate human-like text for tasks like content creation, customer service, and language translation.
                            </p>
                        </div>
                    </div>
                    <div className="models-buttons">
                        <div className="back-button">
                            <Button onClick={backCalling}>Back</Button>
                        </div>
                        <div className="signin">
                            <Button onClick={pricingNavigate}>
                                Continue to Pricing
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AIModels
