import { Form, Input, Spin, Checkbox } from "antd";
import Button from "antd/lib/button";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, removeUser } from "../../Redux/actions/authActions";
import { POST } from "../../utils/apis";
import { allPaths } from "../../utils/constants";
import {
  convertTitle,
  errorMessage,
  removeToken,
  successMessage,
} from "../../utils/helpers";
import JK_LOGO from "../../assets/JK.png";
import AI_ANALYSIS from "../../assets/Ai_analysis.png";
import { RiWalletLine } from "react-icons/ri";
import { RiCheckboxMultipleLine } from "react-icons/ri";
import { LuShieldCheck } from "react-icons/lu";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";

import "./style.css";
const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appName = useSelector((state) => state?.appReducer?.appName);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [spin, setSpin] = useState(false);
  const [form, setForm] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signin, setSignin] = useState(false);
  const [signup, setSignup] = useState(false);

  useEffect(() => {
    async () => {
      dispatch(removeUser());
      removeToken(navigate);
    };
  }, []);

  const onLogin = async (val) => {
    setLoading(true);

     axios
      .post(POST?.LOGIN, val)
      .then(async (res) => {
        const { data } = res;
        // localStorage.setItem('accesstoken', headers['accesstoken'])
        setLoading(false);
        setEmail(null);
        setPassword(null);
         
        // console.log('LINE 40', data)
        if (data?.success) {
          successMessage("You have successfully logged in!");
          dispatch(loginUser(data?.user));
          navigate(allPaths?.HOME);

          return setTimeout(() => {
            navigate(allPaths?.HOME);
          }, 300);
        }
        // else {
        //     errorMessage(data?.message || 'Something Went Wrong!')
        // }
      })
      .catch(async (err) => {
        errorMessage(err?.response?.data?.message);
        setLoading(false);
        if(err?.response?.data?.message === 'Email verification required!'){
        try {
          const result = await axios.post(POST?.RESEND_OTP,{
           email: val.email, 
          })
          successMessage("OTP has been sent to your email address!")
          navigate(allPaths.OTPVERIFICATION,{state:{
            email:val.email
          }})
          
        } catch (error) {
          errorMessage("Failed to send OTP!")
          
        }
      }

      });
  };

  const onSignup = (val) => {
    setLoading(true);

    axios
      .post(POST?.REGISTER, val)
      .then((res) => {
        const { data } = res;
        setLoading(false);
        setEmail(null);
        setPassword(null);
        if (data?.success) {
          successMessage("We have sent OTP on your email address!");
          navigate(allPaths.OTPVERIFICATION,{state:{
            email:val.email
          }})
          
          dispatch(loginUser(data?.userData));
          return setTimeout(() => {
            setSignin(true);
            setSignup(false);
          }, 300);
        } else {
          errorMessage(data?.message || "Something Went Wrong!");
        }
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const onFinishFailed = (err) => {
    // errorMessage()
  };

  const toggleSwitch = (lft, rgt) => {
    setSignin(lft);
    setSignup(rgt);
  };

  const handleButtonClick = () => {
    // Replace 'admin@jakmet.ai' with the actual email address
    const email = "admin@jakmet.ai";

    // Create the mailto link
    const mailtoLink = `mailto:${email}`;

    // Open the default email client
    window.open(mailtoLink, "_blank");
  };

  //   const googleAnalyticsScript = `
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   gtag('js', new Date());
  //   gtag('config', 'G-N3P09RM7SJ');
  // `;

  useEffect(() => {
    // Google Analytics Script 1
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-N3P09RM7SJ";

    script1.onload = () => {
      // Google Analytics Script 2
      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-N3P09RM7SJ');
      `;

      // Append the second script to the document
      document.head.appendChild(script2);
    };

    // Append the first script to the document
    document.head.appendChild(script1);

    // Cleanup
    return () => {
      document.head.removeChild(script1);
    };
  }, []);

  return (
    <div className="main_container">
      <div className="two-col-container">
        <div className="layout">
          <div className="col col-main left">
            <div className="blue-grad"></div>
            <div className="radial-lines"></div>
            <div className="green-grad"></div>
            <img src={JK_LOGO} className="JK_logo" />
            <p className="gradient tag_line">
              Empower Your Vision with Intelligence.
            </p>
            <p className="label_head">
            Create your own Generative AI App Instantly
            </p>
            <p className="label_desc">
            Spin up a trainable, shareable AI website with the LLM of your choice - GPT-4, Open source Llama, Gemini, Claude.
            No code required, zero charge for hosting or setup, pay only for your API usage.
            </p>
            <div className="two-column green-redial">
              <div className="ai_img">
                <img src={AI_ANALYSIS} />
              </div>
              <div className="right_ls">
                <div>
                  <span className="single_ls">
                    <RiWalletLine className="login_icons" />
                    <p className="ls_p1">Customization at Your Fingertips</p>
                  </span>
                  <p className="ls_p2">
                    - Tailor AI applications to fit your unique organizational
                    needs.
                  </p>
                </div>
                <div>
                  <span className="single_ls">
                    <LuShieldCheck className="login_icons" />
                    <p className="ls_p1">Privacy-First Architecture</p>
                  </span>
                  <p className="ls_p2">
                    - Deploy AI while owning your data, with self-hosting
                    options to keep information secure.
                  </p>
                </div>
                <div>
                  <span className="single_ls">
                    <RiCheckboxMultipleLine className="login_icons" />
                    <p className="ls_p1">Scale with Confidence</p>
                  </span>
                  <p className="ls_p2">
                    - From small startups to large institutions, our platform
                    adapts to your demand.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "50px",
                zIndex: 100000,
                fontSize: "20px",
              }}
            >
              Have any questions?{" "}
              <span
                onClick={() => navigate(allPaths.FAQ)}
                style={{ color: "#4273B8", cursor: "pointer", zIndex: 111111 }}
              >
                See FAQs{" "}
              </span>
              or check out some{" "}
              <span
                onClick={() => navigate(allPaths.USECASES)}
                style={{ color: "#4273B8", cursor: "pointer", zIndex: 111111 }}
              >
                Use Cases
              </span>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  paddingBottom: "50px",
                  zIndex: 100000,
                }}
              >
                <div>
                  <a href="https://www.facebook.com/profile.php?id=61555510916560">
                    <FaFacebook
                      style={{
                        width: "45px",
                        height: "45px",
                        margin: "10px",
                        color: "#4273B8",
                      }}
                    />
                  </a>

                  <a href="https://www.instagram.com/jakmetai/">
                    <img
                      style={{
                        margin: "10px",
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg"
                      width={40}
                      height={40}
                      alt="instagram"
                    />
                  </a>
                  <a href="https://www.tiktok.com/@jakmetai">
                    <img style={{
                      margin: "10px",
                    }}
                      src="https://static-00.iconduck.com/assets.00/tiktok-icon-891x1024-ozp1clb4.png"
                      width={40}
                      height={40}
                      alt="tiktok"
                    />
                  </a>

                  <a href="https://twitter.com/JakMetAI">
                    <img
                      style={{
                        margin: "10px",
                      }}
                      src="https://about.twitter.com/content/dam/about-twitter/x/brand-toolkit/logo-black.png.twimg.1920.png"
                      width={35}
                      height={35}
                      alt="twitter"
                    />
                  </a>

                  <a href="https://www.linkedin.com/company/95683896">
                    <img
                      style={{
                        margin: "10px",
                      }}
                      src="https://cdn0.iconfinder.com/data/icons/shift-logotypes/32/Linkedin-128.png"
                      width={45}
                      height={45}
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {!signin && !signup ? (
            <div className="col col-main right">
              <div class="radial-lines-r"></div>
              <p className="welcome">Welcome to</p>
              <p className="jakmet">Your AI Frontier</p>
              <div className="login_btn_cont">
                <div className="create">
                <p className="terms-link" onClick={()=>{navigate('/terms-of-service')}}>Terms of Service</p>
                <p className="terms-link" onClick={()=>{window.open('/all-models-prices', '_blank');}}>Pricing</p>

                  <Button
                    // onClick={() => toggleSwitch(false, true)}
                    onClick={handleButtonClick}
                  >
                    Contact us
                  </Button>
                </div>
                
                <div className="signin">
                 <Button onClick={() => toggleSwitch(false, true)}>
                  Sign up
                 </Button>
               </div>
                <div className="signin">
                  <Button onClick={() => toggleSwitch(true, false)}>
                    Sign in
                  </Button>
                </div>
                
              </div>
              {/* <div className="or-divider">
                                <span className="divider-line-l"></span>
                                <span className="or-text">or</span>
                                <span className="divider-line-r"></span>
                            </div> */}

              {/* <div className='google'>
                                <Button><FcGoogle />Log In with Google</Button>
                            </div>
                            <div className='fb'>
                                <Button><FaFacebook />Log In with Facebook</Button>
                            </div> */}
            </div>
          ) : signin && !signup ? (
            <div className="col col-main right">
              <div class="background-color"></div>
              <div class="radial-lines-r"></div>
              <p className="welcome">Welcome back</p>
              <div style={{ padding: "10px 40px" }}>
                <Form
                  name="Register"
                  hideRequiredMark
                  initialValues={{
                    remember: true,
                  }}
                  layout="vertical"
                  encType="multipart/form-data"
                  onFinish={onLogin}
                  onFinishFailed={onFinishFailed}
                  ref={(e) => setForm(e)}
                  data-testid={"signup-form"}
                >
                  <div>
                    <Form.Item
                      label="Email"
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                        {
                          type: "email",
                          message: "Invalid Email!",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        className="login-input"
                        data-testid={"email"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter your password"
                        className="login-input pw-input"
                        data-testid={"password"}
                      />
                    </Form.Item>
                    <div className="two-column" style={{ margin: "30px 0" }}>
                      <Checkbox>Remember me</Checkbox>
                      <span>Forgot Password?</span>
                    </div>
                    <div className="signin signInFull">
                      <Button
                        htmlType="submit"
                        loading={loading}
                        style={{ width: "100%" }}
                      >
                        Sign in
                      </Button>
                    </div>
                  </div>
                </Form>
                <div className="or-divider">
                  <span className="divider-line-l"></span>
                  <span className="or-text">or</span>
                  <span className="divider-line-r"></span>
                </div>
                <div className="signin signInFull">
                  <Button
                    onClick={handleButtonClick}
                    // onClick={() => toggleSwitch(false, true)}
                    style={{ width: "100%" }}
                  >
                    Contact us
                  </Button>
                </div>
                {/* <center>
                                    <div>
                                        Don't you have an account?
                                        <span
                                            style={{ cursor: 'pointer', color: '#4273B8', marginLeft: '4px', zIndex: 1000 }}
                                            onClick={() => toggleSwitch(signup, !signin)}>
                                            Sign up now
                                        </span>
                                    </div>
                                </center> */}
              </div>
              {/* <div className="or-divider">
                                <span className="divider-line-l"></span>
                                <span className="or-text">or</span>
                                <span className="divider-line-r"></span>
                            </div>
                            <div className='google'>
                                <Button><FcGoogle />Log In with Google</Button>
                            </div>
                            <div className='fb'>
                                <Button><FaFacebook />Log In with Facebook</Button>
                            </div> */}
            </div>
          ) : !signin && signup ? (
            <div className="col col-main right">
              <div class="background-color"></div>
              <div class="radial-lines-r"></div>
              <p className="welcome">Welcome</p>
              <div style={{ padding: "10px 40px" }}>
                <Form
                  name="Register"
                  hideRequiredMark
                  initialValues={{
                    remember: true,
                  }}
                  layout="vertical"
                  encType="multipart/form-data"
                  onFinish={onSignup}
                  onFinishFailed={onFinishFailed}
                  ref={(e) => setForm(e)}
                  data-testid={"signup-form"}
                >
                  <div>
                    <Form.Item
                      label="Email"
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Email is required",
                        },
                        {
                          type: "email",
                          message: "Invalid Email!",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        className="login-input"
                        data-testid={"email"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Password is required",
                        },
                        {
                          min: 8,
                          message:
                            "Password must be greater than or equal to 8",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter your password"
                        className="login-input pw-input"
                        data-testid={"password"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      hasFeedback
                      name="Confirm Password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Re-enter your password"
                        className="login-input pw-input"
                        data-testid={"password"}
                      />
                    </Form.Item>
                    <div className="two-column" style={{ margin: "30px 0" }}>
                      <p>
                        By creating an account you agree to the{" "}
                        <span style={{ color: "#4273b8" }}>terms of use</span>{" "}
                        and our{" "}
                        <span style={{ color: "#4273b8" }}>privacy policy</span>
                      </p>
                    </div>
                    <div className="signin signInFull">
                      <Button
                        htmlType="submit"
                        loading={loading}
                        style={{ width: "100%" }}
                      >
                        Create account
                      </Button>
                    </div>
                    <div className="or-divider">
                      <span className="divider-line-l"></span>
                      <span className="or-text">or</span>
                      <span className="divider-line-r"></span>
                    </div>
                  </div>
                </Form>
                <div className="signin signInFull">
                  <Button
                    onClick={() => toggleSwitch(true, false)}
                    style={{ width: "100%" }}
                  >
                    Sign in
                  </Button>
                </div>
              </div>

              {/* <div className="or-divider">
                                <span className="divider-line-l"></span>
                                <span className="or-text">or</span>
                                <span className="divider-line-r"></span>
                            </div>
                            <div className='google'>
                                <Button><FcGoogle />Log In with Google</Button>
                            </div>
                            <div className='fb'>
                                <Button><FaFacebook />Log In with Facebook</Button>
                            </div> */}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
